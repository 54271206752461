import styled from "styled-components"

export const StyledLatestPosts = styled.section`
  margin: 0;
  padding: 0;

  @media(max-width: 1024px) {
    padding: 0;
  }

  @media(min-width: 1480px) {
    padding: 0 var(--borderSpacing);
  }

  .container {
    padding: 30px;
    background-color: #efecea;

    .intro__area {
        margin-bottom: 10px;
        h2 {
            font-size: 88px;
            line-height: 76px;
            border: none;
        }

        h3 {
            font-size: var(--h3);
        }
    }

    @media (min-width: 768px) {
      padding: 30px 120px 120px;
      > div {
        width: 66.666%;
        max-width: 700px;
      }
    }

  }
`
